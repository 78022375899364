import * as types from './types';

// state definition
const initialState = {
    onlineOrders: [],
    offlineOrders: [],
    allOrders: [],
    userOrders: [],
    selectedOrder: {},
    epicorOrderData: {},
    loading: true,
    epicorSettingsLoading: true,
    meta: {},
    rewardSettingLoading: true,
    rewardSetting: false,
    unsyncedGivexOrders: [],
    givexOrdersLoading: false
};

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.MARK_REWARD_AS_MANUALLY_PROCESSED_SUCCESS:
        case types.GET_ORDER_SUCCESS:
            return {
                ...state,
                selectedOrder: action.data
            };
        case types.GET_ALL_ORDERS_SUCCESS:
            return {
                ...state,
                allOrders: action.data,
                meta: action.meta
            };
        case types.GET_USERS_ORDERS_SUCCESS:
            return {
                ...state,
                userOrders: action.data
            };
        case types.SUBMIT_TO_EPICOR_SUCCESS:
            return {
                ...state,
                selectedOrder: { ...state.selectedOrder, ...action.data }
            };
        case types.ORDERS_LOADING_STATE:
            return {
                ...state,
                loading: action.state
            };

        case types.GET_ORDER_EPICOR_DATA_SUCCESS:
            return {
                ...state,
                epicorOrderData: action.epicorData
            };

        case types.GET_ORDER_EPICOR_DATA_ERROR:
            return {
                ...state,
                epicorOrderData: {}
            };

        case types.EPICOR_SETTING_LOADING_STATE:
            return {
                ...state,
                epicorSettingsLoading: action.state
            };

        case types.UPDATE_EPICOR_SETTING_SUCCESS:
            return {
                ...state,
                epicorSetting: action.data
            };

        case types.GET_EPICOR_SETTING_SUCCESS:
            return {
                ...state,
                epicorSetting: action.response.setting
            };

        case types.UPDATE_ORDER_SUCCESS_TARGET_UUID:
            return {
                ...state,
                selectedOrder: {
                    ...state.selectedOrder,
                    ...action.order}
            };

        case types.REWARD_SETTING_LOADING_STATE:
            return {
                ...state,
                rewardSettingLoading: action.state
            };

        case types.GET_REWARD_SETTING_SUCCESS:
            return {
                ...state,
                rewardSetting: action.value
            };

        case types.UPDATE_REWARD_SETTING_SUCCESS:
            return {
                ...state,
                rewardSetting: action.value
            };

        case types.GIVEX_ORDERS_LOADING:
            return {
                ...state,
                givexOrdersLoading: action.state
            };

        case types.GET_UNSYNCED_GIVEX_ORDERS_SUCCESS:
            return {
                ...state,
                unsyncedGivexOrders: action.data
            };

        default:
            return state;
    }
};

export default ordersReducer;

export const selectAllOrders = state => state.orders.allOrders;
export const selectUserOrders = state => state.orders.userOrders;
export const selectOrdersLoading = state => state.orders.loading;
export const selectOrder = state => state.orders.selectedOrder;
export const selectOrderEpicorData = state => state.orders.epicorOrderData;
export const selectEpicorSettingLoading = state =>
    state.orders.epicorSettingsLoading;
export const selectEpicorSetting = state => state.orders.epicorSetting;
export const selectOrdersMeta = state => state.orders.meta;
export const selectRewardSetting = state => state.orders.rewardSetting;
export const selectRewardSettingLoading = state =>
    state.orders.rewardSettingLoading;
export const selectGivexOrdersLoading = state =>
    state.orders.givexOrdersLoading;
export const selectUnsyncedGivexOrders = state =>
    state.orders.unsyncedGivexOrders;
