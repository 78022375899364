import React, { Component, createRef } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as orderTypes from '@/state/orders/types';
import * as loyaltyTypes from '@/state/loyalty/types';

import {
    selectOrder,
    selectOrderEpicorData,
    selectOrdersLoading
} from '@/state/orders/reducers';
import { selectOrderLoyalty } from '@/state/loyalty/reducers';

import OrderDetailsBox from '@/components/Orders/OrderDetails';
import ItemsOrdered from '@/components/Orders/ItemsOrdered';
import ShippingDetails from '@/components/Orders/ShippingDetails';
import BillingDetails from '@/components/Orders/BillingDetails';
import EpicorDetails from '@/components/Orders/EpicorDetails';
import LoyaltyDetails from '@/components/Orders/LoyaltyDetails';
import CustomerDetails from '@/components/Orders/CustomerDetails';
import SubHeader from '@/components/SubHeader/SubHeader';
import OrderShipping from '@/components/OrderShipping/OrderShipping';
import { validateBilling, validateShipping } from "@/constants/validation";

class OrderDetails extends Component {
    constructor(props) {
        super();
        this.shippingRef = createRef(); // Create ref for validation
        const id = this.getIdFromRoute(props);
        props.getOrder(id);
        props.getOrderLoyalty(id);
        props.getOrderEpicorData(id);
        this.state = {
            saveDisabled: true,
            orderNote: props.selectedOrder.orderNote || '', // Initialize order note
            billingAddress: props.selectedOrder.customer?.billingAddress || {}, // Initialize billing address
            shippingAddress: props.selectedOrder.customer?.shippingAddress || {},
        };
    }


    componentDidUpdate(prevProps) {
        const { selectedOrder } = this.props;

        if (prevProps.selectedOrder.name !== selectedOrder.name) {
            this.setState({
                orderNote: selectedOrder.orderNote || '',
                billingAddress: selectedOrder.customer?.billingAddress || {},
                shippingAddress: selectedOrder.customer?.shippingAddress || {},
            });
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)
    }

    getIdFromRoute = props => {
        const {
            match: { params }
        } = props;
        console.log("Extracted orderId:", params.id);
        const { id } = params;
        return params.id;
    };

    onViewUser = uuid => {
        const { history } = this.props;
        history.push(`/users/${uuid}`);
    };

    onSave = async () => {
        const orderId = this.getIdFromRoute(this.props);
        if (!orderId) {
            return;
        }
        const { updateOrder, selectedOrder, getOrder } = this.props;
        const { orderNote, billingAddress, shippingAddress } = this.state;

        try {
            const updatedOrder = {
                ...selectedOrder,
                orderNote,
                customer: {
                    ...selectedOrder.customer,
                    billingAddress: {
                        ...selectedOrder.customer.billingAddress,
                        ...billingAddress
                    },
                    shippingAddress: {
                        ...selectedOrder.customer.shippingAddress,
                        ...shippingAddress
                    },
                },
            };



            // Dispatch the UPDATE_ORDER action
            await updateOrder(orderId, updatedOrder);

            // Refetch the order
            await getOrder(orderId);
            this.setState({
                saveDisabled: true
            });
        } catch (error) {
            console.error('Save failed:', error);
        }
    };

    onChange = data => {
        // this.setState({
        //     ...data,
        //     saveDisabled: true
        // });
    };

    handleOrderNoteChange = (orderNote) => {
        this.setState(
            (prevState) => ({
                ...prevState,
                orderNote,
                saveDisabled: false,
            })
        );
    };


    handleBillingChange = (billingAddress) => {
        this.setState(
            (prevState) => ({
                ...prevState,
                billingAddress,
                saveDisabled: false,
            })
        );
    };

    handleShippingChange = (shippingAddress) => {
        this.setState(
            (prevState) => ({
                ...prevState,
                shippingAddress,
                saveDisabled: false,
            })
        );
    };

    setSaveState = (value) => {
        this.setState((prevState) => ({
            saveDisabled: value,
        }))
    }

    // shouldComponentUpdate = (nextProps, nextState) => {
    //     const { loadingState, readOnly, orderLoyalty } = this.props;
    //     const { saveDisabled } = this.state;

    //     // console.log('SCUP :', nextProps.loadingState !== loadingState || (saveDisabled && !nextState.saveDisabled));
    //     return (
    //         nextProps.loadingState !== loadingState ||
    //         (saveDisabled && !nextState.saveDisabled) ||
    //         nextProps.readOnly !== readOnly ||
    //         nextProps.orderLoyalty !== orderLoyalty
    //     );
    // };

    render() {
        const {
            selectedOrder,
            orderLoyalty,
            orderEpicorData,
            readOnly
        } = this.props;

        const {
            source,
            createdAt,
            updatedAt,
            customer,
            lineItems,
            shipping,
            totals,
            discountCodes,
            name,
            orderNote,
            noteAttributes,
            subscription,
            subscriptionCount,
            loadingState,
            orderId
        } = selectedOrder;

        const {
            fomPoints,
            fomCreditSpent,
            processed_at: processedAt,
            manuallyProcessed,
            meta,
            fomCurrency
        } = orderLoyalty;

        const { transactionId, request, response, skus } = meta || {};
        const failedOrder = selectedOrder.epicorId === ''

        const { shippingAddress, fom, billingAddress } = customer || {};
        const shippingErrors = source && source.toLowerCase() === "shopify" ? validateShipping(this.state.shippingAddress) : null
        const billingErrors = source && source.toLowerCase() === "shopify" ? validateBilling(this.state.billingAddress) : null

        return (
            <React.Fragment>
                <SubHeader
                    onSave={this.onSave}
                    // saving={saving}
                    lastUpdated={selectedOrder.updatedAt}
                    readOnly={readOnly}
                    disabled={this.state.saveDisabled || shippingErrors || billingErrors || !failedOrder}
                />
                <div style={{ marginTop: 60 }} />
                <OrderDetailsBox
                    source={source}
                    createdAt={createdAt}
                    updatedAt={updatedAt}
                    name={name}
                    readOnly={readOnly}
                    subscription={subscription}
                    subscriptionCount={subscriptionCount}
                    noteAttributes={noteAttributes}
                />
                <OrderShipping orderId={selectedOrder.epicorId || -1} />
                <ItemsOrdered
                    items={selectedOrder.lineItems}
                    shipping={selectedOrder.shipping}
                    totals={selectedOrder.totals}
                    discountCodes={selectedOrder.discountCodes}
                    noteAttributes={selectedOrder.noteAttributes}
                    orderNote={orderNote} // Pass orderNote from state
                    readOnly={readOnly}
                    onChange={this.handleOrderNoteChange} // Pass onChange handler
                />
                <EpicorDetails
                    order={selectedOrder}
                    epicorData={orderEpicorData}
                    readOnly={readOnly}
                    onChange={this.onChange}
                    orderSaveState={this.state.saveDisabled}
                />
                <LoyaltyDetails
                    fom={fom}
                    accruedPoints={fomPoints}
                    fomCreditSpent={fomCreditSpent}
                    readOnly={readOnly}
                    orderDate={createdAt}
                    processedAt={processedAt}
                    manuallyProcessed={manuallyProcessed}
                    transactionId={transactionId}
                    request={request}
                    response={response}
                    orderId={orderId}
                    fomCurrency={fomCurrency}
                    loyaltySkus={skus}
                />
                <ShippingDetails
                    shippingAddress={this.state.shippingAddress}
                    readOnly={readOnly || !failedOrder}
                    onChange={this.handleShippingChange}
                    errors={shippingErrors}
                />
                <BillingDetails
                    billingAddress={this.state.billingAddress}
                    onChange={this.handleBillingChange}
                    readOnly={readOnly || !failedOrder}
                    errors={billingErrors}
                />
                <CustomerDetails
                    customer={customer}
                    onViewUser={this.onViewUser}
                    readOnly={readOnly}
                />
            </React.Fragment>
        );
    }
}

OrderDetails.propTypes = {
    history: PropTypes.object.isRequired,
    selectedOrder: PropTypes.object.isRequired,
    orderLoyalty: PropTypes.object.isRequired,
    orderEpicorData: PropTypes.object.isRequired,

    getOrder: PropTypes.func.isRequired,
    getOrderLoyalty: PropTypes.func.isRequired,
    getOrderEpicorData: PropTypes.func.isRequired,
    updateOrder: PropTypes.func.isRequired,

    readOnly: PropTypes.bool.isRequired,
    loadingState: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    selectedOrder: selectOrder(state),
    orderLoyalty: selectOrderLoyalty(state),
    orderEpicorData: selectOrderEpicorData(state),
    loadingState: selectOrdersLoading(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getOrder: orderId => ({ type: orderTypes.GET_ORDER, orderId }),
            getOrderLoyalty: orderId => ({
                type: loyaltyTypes.GET_ORDER_LOYALTY,
                orderId
            }),
            getOrderEpicorData: orderId => ({
                type: orderTypes.GET_ORDER_EPICOR_DATA,
                orderId
            }),
            updateOrder: (orderId, orderData) => ({
                type: orderTypes.UPDATE_ORDER,
                orderId,
                orderData
            })
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OrderDetails)
);
