import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Loader from '@/components/Loader/Loader';
import Box from '@/components/Box/Box';
import SelectBox from '@/components/SelectBox/SelectBox';
import SelectInput from '@/components/TextInput/SelectInput';


import {
    selectCollections,
    selectSelectedProduct
} from '@/state/products/reducers';

const styles = makeStyles({
    container: {
        textAlign: 'center'
    },
    title: {
        margin: '20px 0px',
        fontSize: 20,
        lineHeight: 1.2,
        textAlign: 'left'
    },
    subtitle: {
        margin: '5px 0px',
        fontSize: 14,
        lineHeight: 1,
        textAlign: 'left'
    },
});


const ProductCollection = props => {
    const classes = styles();
    const { readOnly, onUpdate, loading, isNew, onObjectUpdate } = props;

    const collections = useSelector(state => selectCollections(state));
    const [selectedCollections, setSelectedCollections] = React.useState([]);
    const [selectedParentCollections, setSelectedParentCollections] = React.useState([]);
    const [pCollection, setPrimaryCollection] = React.useState('');

    const onPrimaryCollectionChange = type => event => {
        setPrimaryCollection(event);
        onObjectUpdate({ primaryCollection: event });
    }
    

    const product = useSelector(state => selectSelectedProduct(state));
    const { categories, primaryCollection } = product;

    const mapParentCollectionToSelect = categoryArray => {
        return categoryArray.reduce((acc, c) => {
            // const parentCollections = collections.map(collection => collection.id);
            acc.push({
                label: c.title,
                value: c.id,
            });
            return acc;
        }, []);
    };

    const mapChildCollectionToSelect = categoryArray => {
        if (categoryArray && categoryArray.length > 0) {
            return categoryArray.reduce((acc, c) => {
                const parent = collections.find(col => col.id === c);
                if(!parent) {
                    return acc;
                }
                parent.subCollections.forEach(child => {
                    acc.push({
                        label: child.title,
                        value: child.id,
                    });
                });
                return acc;
            }, []);
        }
        return [];
    };

    const mapCollectionToSelectPrimaryCollection = () => {
        return selectedParentCollections.reduce((acc, c) => {
            const parent = collections.find(col => col.id === c);
            if(parent) {
                acc.push({
                    label: parent.title,
                    value: parent.title
                });
            }
            return acc;

        }, []);
    };

    if (!isNew) {
        useEffect(() => {
            if (!loading) {
                // const parentCollections = mapParentCollectionToSelect(categories).map(p => p.value);
                const parentCollections = mapParentCollectionToSelect(collections).map(p => p.value);
                if(categories!==null){
                const selectedParents = categories.filter(p => parentCollections.includes(p.id)).map(c => c.id);
                setSelectedParentCollections(selectedParents);

                const childCollections = categories.filter(p => !parentCollections.includes(p.id)).map(p => p.id)
                setSelectedCollections(childCollections);
                setPrimaryCollection(primaryCollection);
                }
            }
        }, [loading]);
    }

    const handleParentSelectedCollection = collectionId => {
        const _selectedCollections = [...selectedParentCollections];

        const idx = _selectedCollections.indexOf(collectionId);
        if (idx > -1) {
            _selectedCollections.splice(idx, 1);

            // if parent is unselected, unselect subCollections as well
            const parent = collections.find(c => c.id === collectionId);
            if(parent) {
                parent.subCollections.forEach(child => {
                    const childIndex = selectedCollections.indexOf(child.id);
                    if(childIndex > -1) {
                        selectedCollections.splice(childIndex, 1);
                    }
                });
            }
        } else {
            _selectedCollections.push(collectionId);
        }

        onPrimaryCollectionChange('primaryCollection');
        setSelectedParentCollections(_selectedCollections);
        onUpdate([ ..._selectedCollections, ...selectedCollections]);
    };

    const handleChildSelectedCollection = collectionId => {
        const _selectedCollections = [...selectedCollections];

        const idx = _selectedCollections.indexOf(collectionId);
        if (idx > -1) {
            _selectedCollections.splice(idx, 1);
        } else {
            _selectedCollections.push(collectionId);
        }

        setSelectedCollections(_selectedCollections);
        onUpdate([ ..._selectedCollections, ...selectedParentCollections]);
    };

    const childCollections = mapChildCollectionToSelect(selectedParentCollections);

    return (
        <Box title="Collection" className={classes.container}>
            <Typography className={classes.subtitle}>Collections currently will not syncronise to Shopify on save, please edit in Shopify for collections instead. This will be resolved in a future update.</Typography>
            <Loader loading={loading}>
                
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography className={classes.title}>Collection(s)</Typography>
                        <SelectBox
                            rootStyle={{ marginTop: 10 }}
                            options={mapParentCollectionToSelect(collections)}
                            selected={selectedParentCollections}
                            onClick={readOnly ? undefined : handleParentSelectedCollection}
                            checkbox
                        />
                        <SelectInput 
                            label="Primary Collection"
                            value={pCollection}
                            options={mapCollectionToSelectPrimaryCollection()}
                            onChange={onPrimaryCollectionChange()}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>                    
                        <Typography className={classes.title}>Subcollection(s)</Typography>
                        <SelectBox
                            rootStyle={{ marginTop: 10 }}
                            options={childCollections}
                            selected={selectedCollections}
                            onClick={readOnly ? undefined : handleChildSelectedCollection}
                            checkbox
                            subheader={childCollections.length > 0 ? null : 'Select a collection'}
                        />
                    </Grid>
                </Grid>
            </Loader>
        </Box>
    );
};

ProductCollection.propTypes = {
    isNew: PropTypes.bool,
    readOnly: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    onObjectUpdate: PropTypes.func.isRequired
};

ProductCollection.defaultProps = {
    isNew: false
};

export default ProductCollection;
