import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import Typography from '@material-ui/core/Typography';

import Box from '@/components/Box/Box';
import Loader from '@/components/Loader/Loader';
import ImagePlaceholder from '@/components/ImagePlaceholder/ImagePlaceholder';
import ImagePicker from '@/components/ImagePicker/ImagePicker';

const styles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    imageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 150,
        height: 150,
        backgroundColor: '#dadada',
        marginBottom: 10,
        img: {
            objectFit: "contain"
        }
    },
    icon: {
        color: '#bababa'
    },
    helper: {
        marginTop: 10,
        fontSize: 12,
        fontWeight: 300,
        color: '#5a5a5a'
    }
});

const OfferImage = props => {
    const { offer, onChange, loading, readOnly } = props;

    const [imageState, setImageState] = React.useState({
        src: null,
        isNew: false
    });

    useEffect(() => {
        if (!loading) {
            const { imageUrl } = offer;
            const { image } = offer;

            if (imageUrl) {
                setImageState(prevState => ({
                    ...prevState,
                    src: imageUrl
                }));
            } else {
                setImageState({ ...image });
            }
        }
    }, [loading]);

    const classes = styles();

    const onAddImage = imageData => {
        setImageState({ src: imageData, isNew: true });
        onChange({ src: imageData, isNew: true });
    };

    return (
        <Box className={classes.container}>
            <Loader loading={loading}>
                <ImagePlaceholder imageUrl={imageState.src} />

                {!readOnly &&
                    <ImagePicker
                        icon={<AddPhotoIcon fontSize="small" />}
                        iconPosition="start"
                        label="Add image"
                        style={{
                            fontSize: 14,
                            width: 150,
                            padding: '8px 5px'
                        }}
                        onImageLoad={onAddImage}
                    />
                }
            </Loader>
        </Box>
    );
};

OfferImage.propTypes = {
    offer: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    loading: PropTypes.bool
};

OfferImage.defaultProps = {
    loading: false
};

export default OfferImage;
