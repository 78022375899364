import { call, put, takeLatest, select } from 'redux-saga/effects';
import api from '@/state/common/actions';

import { selectUser } from './reducers';
import * as actions from './actions';
import * as types from './types';
import * as modalTypes from '../modal/types';
import * as snackTypes from '../snack/types';


const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

// common
function* getUsers(request) {
    try {
        // debounce the query
        yield put({ type: types.USERS_LOADING_STATE, state: true });
        yield call(delay, 200); // wait 200ms before executing the search
        const response = yield call(actions.getUsers, request.page, request.pageSize, request.search, request.permissions, request.status, request.sortBy, request.order);
        const { users, meta } = response.data;
        yield put({ type: types.GET_USERS_SUCCESS, users, meta });
        yield put({ type: types.USERS_LOADING_STATE, state: false });

    } catch (error) {
        yield put({ type: types.GET_USERS_ERROR, error });
        yield put({ type: types.USERS_LOADING_STATE, state: false });
    }
}

function* getUser(userUuid) {
    try {
        yield put({ type: types.USERS_LOADING_STATE, state: true });
        const user = yield call(actions.getUser, userUuid);
        yield put({ type: types.GET_USER_SUCCESS, user });
        yield put({ type: types.USERS_LOADING_STATE, state: false });

    } catch (error) {
        yield put({ type: types.GET_USER_ERROR, error });
        yield put({ type: types.USERS_LOADING_STATE, state: false });
    }
}

function* updateUser(request) {
    try {
        yield put({ type: types.USERS_LOADING_STATE, state: true });
        const selectedUser = yield select(selectUser);
        const { data } = request;
        const mergeResult = yield call(actions.updateUser, selectedUser, data);

        // make API call
        yield call(api, `/v1/user/update/${selectedUser.uuid}`, 'PUT', { user: mergeResult });
        // assign mergeResult to redux here
        yield put({ type: types.UPDATE_USER_SUCCESS, user: mergeResult });
        yield put({ type: types.USERS_LOADING_STATE, state: false });
        yield put({ type: types.UPDATE_USER_ERROR, error: {} });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'User updated successfully',
            open: true,
            props: { variant: 'success' }
        });
    } catch (error) {
        const { response: { data } } = error;
        const { errors, message } = data;

        if (error.response.status === 422) {
            yield put({ type: types.UPDATE_USER_ERROR, error: error.response.data.errors });
            yield put({ type: types.USERS_LOADING_STATE, state: false });
            return;
        }

        if (error.response.status === 409) {

        }

        // const usedBy = Object.keys(errors).filter(e => !errors[e]);

        yield put({ type: types.UPDATE_USER_ERROR, error: errors });
        yield put({ type: types.USERS_LOADING_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: `${message}`,
            open: true,
            props: { variant: 'error' }
        });
    }
}

function* updateUserAddress(request) {
    try {
        yield put({ type: types.USERS_LOADING_STATE, state: true });
        const selectedUser = yield select(selectUser);
        const { data } = request;
        // make API call
        const result = yield call(api, `/v1/user/address/${selectedUser.uuid}`, 'PUT', { address: data.address });

        yield put({ type: types.UPDATE_USER_ADDRESS_SUCCESS, user: result.data });
        yield put({ type: types.USERS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.UPDATE_USER_ADDRESS_ERROR, error });
        yield put({ type: types.USERS_LOADING_STATE, state: false });

    }
}

function* createUserAddress(request) {
    try {
        yield put({ type: types.USERS_LOADING_STATE, state: true });
        const selectedUser = yield select(selectUser);
        const { data } = request;
        // make API call
        const result = yield call(api, `/v1/user/address/${selectedUser.uuid}`, 'POST', { address: data.address });

        yield put({ type: types.CREATE_USER_ADDRESS_SUCCESS, user: result.data });
        yield put({ type: types.USERS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.CREATE_USER_ADDRESS_ERROR, error });
        yield put({ type: types.USERS_LOADING_STATE, state: false });
    }
}

function* deleteUserAddress(request) {
    try {
        yield put({ type: types.USERS_LOADING_STATE, state: true });
        const selectedUser = yield select(selectUser);
        const { data } = request;
        // make API call
        const result = yield call(api, `/v1/user/address/${selectedUser.uuid}/${data.address.shopifyId}`, 'DELETE');

        yield put({ type: types.DELETE_USER_ADDRESS_SUCCESS, user: result.data });
        yield put({ type: types.USERS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.DELETE_USER_ADDRESS_ERROR, error });
        yield put({ type: types.USERS_LOADING_STATE, state: false });
    }
}

function* archiveUser(request) {
    try {
        const { uuid } = request;
        yield call(api, `/v1/user/${uuid}`, 'DELETE');
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false })
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'User has been archived',
            open: true,
            props: { variant: 'success' }
        });
    } catch (error) {
        yield put({ type: types.ARCHIVE_USER_ERROR });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false })
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Something went wrong with archiving the user',
            open: true,
            props: { variant: 'error' }
        });
    }
}

function* restoreUser(request) {
    try {
        const { uuid } = request;
        yield call(api, `/v1/user/restore/${uuid}`, 'PUT');
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false })
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'User has been restored',
            open: true,
            props: { variant: 'success' }
        });
    } catch (error) {
        yield put({ type: types.RESTORE_USER_ERROR });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false })
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Something went wrong with restoring the user',
            open: true,
            props: { variant: 'error' }
        });
    }
}

function* forceDeleteUser(request) {
    try {
        const { uuid } = request;
        yield call(api, `/v1/user/${uuid}/force`, 'DELETE');
    } catch (error) {
        yield put({ type: types.FORCE_DELETE_USER_ERROR });
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false })
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Something went wrong with deleting the user',
            open: true,
            props: { variant: 'error' }
        });
    }
}

// flow
export default [
    takeLatest(types.GET_USERS, getUsers),
    takeLatest(types.GET_USER, getUser),
    takeLatest(types.UPDATE_USER, updateUser),
    takeLatest(types.UPDATE_USER_ADDRESS, updateUserAddress),
    takeLatest(types.CREATE_USER_ADDRESS, createUserAddress),
    takeLatest(types.DELETE_USER_ADDRESS, deleteUserAddress),
    takeLatest(types.ARCHIVE_USER, archiveUser),
    takeLatest(types.RESTORE_USER, restoreUser),
    takeLatest(types.FORCE_DELETE_USER, forceDeleteUser),
];
