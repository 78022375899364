import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as types from './types';
import * as modalTypes from '../modal/types';
import * as snackTypes from '../snack/types';

// products
function* getProducts() {
    try {
        yield put({ type: types.PRODUCTS_LOADING_STATE, state: true });
        // get products
        const productsResponse = yield call(actions.getProducts);
        const { products, meta } = productsResponse.data;
        // get categories
        const categoriesResponse = yield call(actions.getShopifyCategories);
        const categories = categoriesResponse.data;
        yield put({ type: types.GET_SHOPIFY_COLLECTIONS_SUCCESS, collections: categories })
        // perform categories merge on data
        const mergedData = yield call(actions.populateCategoriesForProducts, products, categories);

        yield put({ type: types.GET_PRODUCTS_SUCCESS, products: mergedData, meta });
        yield put({ type: types.PRODUCTS_LOADING_STATE, state: false });

    } catch (error) {
        yield put({ type: types.GET_PRODUCTS_ERROR, error });
        yield put({ type: types.PRODUCTS_LOADING_STATE, state: false });
    }
}

function* getProduct(request) {
    try {
        yield put({ type: types.PRODUCTS_LOADING_STATE, state: true });
        const response = yield call(actions.getProduct, request);
        const { data } = response;

        yield put({ type: types.GET_PRODUCT_DETAIL_SUCCESS, product: data });
        yield put({ type: types.PRODUCTS_LOADING_STATE, state: false });
        // get categories
        const categoriesResponse = yield call(actions.getShopifyCategories);
        const categories = categoriesResponse.data;
        yield put({ type: types.GET_SHOPIFY_COLLECTIONS_SUCCESS, collections: categories })

        // perform categories merge on data
        const mergedData = yield call(actions.populateCategoriesForProducts, [data], categories);
        yield put({ type: types.GET_PRODUCT_DETAIL_SUCCESS, product: mergedData[0] });
        yield put({ type: types.PRODUCTS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_PRODUCT_DETAIL_ERROR, error });
        yield put({ type: types.PRODUCTS_LOADING_STATE, state: false });
    }
}


function* getCollections() {
    try {
        yield put({ type: types.PRODUCTS_LOADING_STATE, state: true });
        const response = yield call(actions.getShopifyCategories);

        yield put({ type: types.GET_SHOPIFY_COLLECTIONS_SUCCESS, collections: response.data });
        yield put({ type: types.PRODUCTS_LOADING_STATE, state: false });
    } catch (error) {
        yield put({ type: types.GET_SHOPIFY_COLLECTIONS_ERROR, error });
        yield put({ type: types.PRODUCTS_LOADING_STATE, state: false });
    }
}

function* updateProduct(request) {
    // console.info('DEBUG: function*updateProduct -> request', request);
    try {
        yield put({ type: types.PRODUCT_SAVE_STATE, state: true });
        // format the data packet
        const { type, uuid, productData } = request;
        const payload = yield call(actions.formatProductPayload, productData);
        yield call(actions.updateProduct, { uuid, payload });
        yield put({ type: types.PRODUCT_SAVE_STATE, state: false });
    } catch (error) {
        yield put({ type: types.UPDATE_PRODUCT_ERROR, error });
        yield put({ type: types.PRODUCT_SAVE_STATE, state: false });
    }
}

function* createProduct(request) {
    try {
        yield put({ type: types.PRODUCT_SAVE_STATE, state: true });
        const { type, productData } = request;
        const payload = yield call(actions.formatProductPayload, productData);
        yield call(actions.createProduct, { payload });

    } catch (error) {

        yield put({ type: types.PRODUCT_SAVE_STATE, state: false });
    }
}

function* deleteProduct(request) {
    try {
        const { uuid, history } = request;

        yield put({ type: types.PRODUCT_SAVE_STATE, state: true });
        yield call(actions.deleteProduct, uuid);
        yield put({ type: modalTypes.MODAL_SET_OPEN_STATE, state: false });
        yield put({ type: types.PRODUCT_SAVE_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Product has been deleted',
            open: true,
            props: { variant: 'success'}
        });
        history.push('/products');
    } catch (error) {
        console.log('TCL: function*deleteProduct -> error', error)
        yield put({ type: types.DELETE_PRODUCT_ERROR, error });
        yield put({ type: types.PRODUCT_SAVE_STATE, state: false });
        yield put({
            type: snackTypes.SET_SNACK,
            content: 'Something went wrong with deleting the product, please try again',
            open: true,
            props: { variant: 'error'}
        });
    }
}


// flow
export default [
    takeLatest(types.GET_PRODUCTS, getProducts),
    takeLatest(types.GET_PRODUCT_DETAIL, getProduct),
    takeLatest(types.GET_SHOPIFY_COLLECTIONS, getCollections),
    takeLatest(types.UPDATE_PRODUCT, updateProduct),
    takeLatest(types.CREATE_PRODUCT, createProduct),
    takeLatest(types.DELETE_PRODUCT, deleteProduct),
];
