import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { selectOrdersLoading } from '@/state/orders/reducers';

import Box from '@/components/Box/Box';
import Loader from '@/components/Loader/Loader';
import TextInput from '@/components/TextInput/TextInput';

const BillingDetails = props => {
    const { billingAddress, readOnly, onChange, errors } = props;

    const loadingState = useSelector(state => selectOrdersLoading(state));

    const handleFieldChange = (fieldName, value) => {
        const updatedData = {
            ...billingAddress,
            [fieldName]: value
        };

        if (onChange) {
            onChange(updatedData);
        }
    };

    return (
        <Box title="Billing details">
            <Loader loading={loadingState}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            label="Name"
                            maxLength={50}
                            value={billingAddress?.name}
                            onChange={(e) => handleFieldChange('name', e.target.value)}
                            readOnly={readOnly}
                            error={!!errors?.name}
                            helperText={errors?.name}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Company"
                            value={billingAddress.company}
                            onChange={(e) => handleFieldChange('company', e.target.value)}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            label="Address 1"
                            maxLength={50}
                            value={billingAddress?.address1}
                            onChange={(e) => handleFieldChange('address1', e.target.value)}
                            readOnly={readOnly}
                            error={!!errors?.address1}
                            helperText={errors?.address1}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            label="Address 2"
                            maxLength={50}
                            value={billingAddress.address2}
                            onChange={(e) => handleFieldChange('address2', e.target.value)}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="City"
                            maxLength={50}
                            value={billingAddress?.city}
                            onChange={(e) => handleFieldChange('city', e.target.value)}
                            readOnly={readOnly}
                            error={!!errors?.city}
                            helperText={errors?.city}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Postcode"
                            value={billingAddress?.postCode}
                            onChange={(e) => handleFieldChange('postCode', e.target.value)}
                            readOnly={readOnly}
                            error={!!errors?.postCode}
                            helperText={errors?.postCode}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="State"
                            value={billingAddress?.state}
                            onChange={(e) => handleFieldChange('state', e.target.value)}
                            readOnly={readOnly}
                            error={!!errors?.state}
                            helperText={errors?.state}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Country"
                            value={billingAddress?.country}
                            onChange={(e) => handleFieldChange('country', e.target.value)}
                            readOnly={readOnly}
                            error={!!errors?.country}
                            helperText={errors?.country}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            label="Phone"
                            value={(billingAddress?.phone === 'null' || billingAddress?.phone === null) ? '' : billingAddress?.phone}
                            onChange={(e) => handleFieldChange('phone', e.target.value)}
                            readOnly={readOnly}
                            error={!!errors?.phone}
                            helperText={errors?.phone}
                        />
                    </Grid>
                </Grid>
            </Loader>
        </Box>
    );
};

BillingDetails.propTypes = {
    billingAddress: PropTypes.shape({
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        postCode: PropTypes.string,
        country: PropTypes.string,
        name: PropTypes.string,
        company: PropTypes.string,
        phone: PropTypes.string
    }),
    readOnly: PropTypes.bool,
    onChange: PropTypes.func
};

BillingDetails.defaultProps = {
    billingAddress: null,
    readOnly: true,
    onChange: null
};

export default BillingDetails;