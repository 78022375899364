import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Arrow from '@material-ui/icons/ArrowDropUp';

import { selectOrdersLoading, selectOrderEpicorData } from '@/state/orders/reducers';
import * as types from '@/state/orders/types';

import Box from '@/components/Box/Box';
import Loader from '@/components/Loader/Loader';
import TextInput from '@/components/TextInput/TextInput';
import Datepicker from '@/components/Datepicker/Datepicker';
import Button from '@/components/Button/Button';
import { Tooltip } from "@material-ui/core";


const EpicorDetails = props => {
    const { order, readOnly, onChange, orderSaveState } = props;
    const { epicorId, epicorSynced } = order;
    const dispatch = useDispatch();

    const loadingState = useSelector(state => selectOrdersLoading(state));
    const epicorOrderData = useSelector(state => selectOrderEpicorData(state));

    const [orderData, setOrderData] = React.useState({
        epicorId: '',
        epicorSynced: null
    });

    const [moreDetails, setMoreDetails] = React.useState({
        expanded: false,
        response: '',
        request: '',
    })

    useEffect(() => {
        if (!loadingState) {
            setOrderData({
                epicorId,
                epicorSynced: epicorSynced
                    ? moment(new Date(epicorSynced))
                    : null
            });
        }
    }, [loadingState]);

    useEffect(() => {
        if (Object.keys(epicorOrderData).length > 0) {
            const { request, response } = epicorOrderData;
            setMoreDetails({ ...moreDetails, request, response });
        } else {
            setMoreDetails({ ...moreDetails, request: '', response: '' })
        }
    }, [epicorOrderData]);

    const disableResubmit = () => {
        if (orderSaveState) {
            return true
        }
        return false
    }

    const onDetailsChange = type => event => {
        setOrderData({
            ...orderData,
            [type]: event.target.value
        })
        onChange({
            [type]: event.target.value
        })
    }


    const styles = makeStyles({
        buttonContainer: {
            marginTop: 10,
            display: 'flex',
        },
        notSubmitted: {
            fontSize: 11,
            color: '#a7a7a7'
        },
        button: {

        },
        moreDetails: {
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 1,
        },
        moreDetailsIcon: {
            transform: moreDetails.expanded ? 'rotateZ(0deg)' : 'rotateZ(180deg)',
        },
        moreDetailsContainer: {
            height: moreDetails.expanded ? 600 : 0,
            paddingTop: moreDetails.expanded ? 20 : 0,
            overflow: 'hidden',
        }
    });

    const submitted = orderData.epicorId.trim().length > 0 && orderData.epicorSynced !== null;

    const classes = styles();

    const onMoreDetails = () => {
        setMoreDetails({ ...moreDetails, expanded: !moreDetails.expanded });
    }

    const onResubmitToEpicor = () => {
        dispatch({ type: types.SUBMIT_TO_EPICOR, order })
    }

    const { source } = order;
    const isWooCommerce = source === 'Woocommerce';
    const isPOS = source === 'POS';

    return (
        <Box title="Epicor details">
            <Loader loading={loadingState}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            value={orderData.epicorId}
                            label="Epicor order ID"
                            readOnly={readOnly}
                            onChange={onDetailsChange('epicorId')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Datepicker
                            label="Date uploaded to Epicor"
                            value={orderData.epicorSynced}
                            readOnly={true}
                        />
                    </Grid>
                </Grid>
                {
                    !isPOS && (
                        isWooCommerce
                            ? <Typography className={classes.notSubmitted}>Legacy WooCommerce order that has already been submitted to Epicor.</Typography>
                            : !submitted && <Typography className={classes.notSubmitted}>This order has not been submitted to Epicor.</Typography>
                    )
                }
                <div className={classes.buttonContainer}>
                    <Tooltip title={!disableResubmit() ? "Please save before submitting" : ''} aria-label="add">
                        <div>
                            <Button
                                disabled={readOnly || (submitted || isWooCommerce || isPOS) || !disableResubmit()}
                                label="Resubmit to Epicor"
                                onClick={onResubmitToEpicor}
                                className={classes.button}
                            />
                        </div>
                    </Tooltip>
                    <div
                        role='presentation'
                        className={classes.moreDetails}
                        onClick={onMoreDetails}
                    >
                        View more details <Arrow className={classes.moreDetailsIcon} />
                    </div>

                </div>
                <div className={classes.moreDetailsContainer}>
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <TextInput
                                label='Epicor Request'
                                value={moreDetails.request}
                                multiline
                                rows={15}
                                rowsMax={15}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                label='Epicor Response'
                                value={moreDetails.response}
                                multiline
                                rows={15}
                                rowsMax={15}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Loader>
        </Box>
    );
};

EpicorDetails.propTypes = {
    order: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,

};

EpicorDetails.defaultProps = {
    readOnly: false,
    onChange: () => { }
};

export default EpicorDetails;
