import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from '@/components/Button/Button';
import Table from '@/components/Table/Table';
import ImagePlaceholder from '@/components/ImagePlaceholder/ImagePlaceholder';

import { selectOffersSaveState } from '@/state/promotions/reducers';

import * as promotionTypes from '@/state/promotions/types';
import * as modalTypes from '@/state/modal/types';

import * as modals from '@/constants/modals';

const styles = makeStyles({
    marginTop: {
        marginTop: 40
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    title: {
        fontSize: 20,
        lineHeight: 1.2
    }
});

const headerData = [
    {
        key: 'imageUrl',
        label: '',
        sortable: false,

        compute: data => {
            const { imageUrl } = data;
            return imageUrl.trim().length > 0 ? imageUrl : null;
        },
        renderer: data => <ImagePlaceholder imageUrl={data} styles={{ width: '100px', height: '100px', margin: 0, objectFit: 'contain' }} />
    },
    {
        key: 'text1',
        label: 'Title',
        compute: data => data,
        renderer: data => `${data.text1}${(data.active === true || data.active === 'true') ? ' (active)' : ''}`,
    },
    {
        key: 'startDate',
        label: 'Start Date',
        compute: data => data.startDate,
        renderer: data => moment(data).format('DD/MM/YYYY')
    },
    {
        key: 'endDate',
        label: 'End Date',
        compute: data => data.endDate,
        renderer: data => moment(data).format('DD/MM/YYYY')
    }
];

const OfferTileList = props => {
    const classes = styles();
    const dispatch = useDispatch();
    const { offers, history, readOnly } = props;

    const offersLoadingState = useSelector(
        state => state.promotions.offersLoading
    );

    const [page, setPage] = React.useState(0);

    const onViewDetails = rowData => {
        const { uuid } = rowData;
        history.push(`/mobile-app/offer-tiles/${uuid}`);
    };

    const onAddTile = () => {
        history.push('/mobile-app/offer-tiles/add');
    };

    const getMenuActions = () => {
        return [
            { label: 'Edit', func: rowData => onViewDetails(rowData) },
            {
                label: 'Delete',
                func: data => {
                    dispatch({
                        type: modalTypes.MODAL_SET_COMPONENT,
                        component: modals.CONFIRMATION_MODAL,
                        props: {
                            onConfirm: () =>
                                dispatch({
                                    type: promotionTypes.DELETE_OFFER,
                                    uuid: data.uuid
                                }),
                            confirmButtonLabel: 'Delete',
                            saveStateSelector: selectOffersSaveState,
                            message: `Are you sure you want to delete '${data.text1}'?`
                        }
                    });
                    dispatch({
                        type: modalTypes.MODAL_SET_OPEN_STATE,
                        state: true
                    });
                }
            }
        ];
    };

    return (
        <React.Fragment>
            <Grid className={classes.marginTop} container spacing={2}>
                <Grid className={classes.flex} item xs={10}>
                    <Typography className={classes.title}>
                        Featured Tiles
                    </Typography>
                </Grid>
                <Grid className={classes.flexEnd} item xs={2}>
                    {!readOnly &&
                        <Button
                            label="+ Add tile"
                            style={{
                                padding: '5px 27px'
                            }}
                            onClick={onAddTile}
                        />
                    }
                </Grid>
            </Grid>
            <Table
                page={page}
                setPage={setPage}
                headerData={headerData}
                tableData={offers}
                showHeader
                menuActions={getMenuActions()}
                pagination
                loading={offersLoadingState}
                onRowClick={onViewDetails}
                defaultSortKey="startDate"
                defaultSortDir="desc"
            />
        </React.Fragment>
    );
};

OfferTileList.propTypes = {
    history: PropTypes.object.isRequired,
    offers: PropTypes.array.isRequired
};

export default withRouter(OfferTileList);
