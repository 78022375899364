import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

import Box from '@material-ui/core/Box';

import OrderFilter from '../components/Filters/OrderFilter';
import Table from '../components/Table/Table';

import { GET_ALL_ORDERS } from '@/state/orders/types';

import {
    selectOrdersLoading,
    selectAllOrders,
    selectOrdersMeta,
    selectUnsyncedGivexOrders,
    selectGivexOrdersLoading
} from '@/state/orders/reducers';

const headerData = [
    {
        key: 'createdAt',
        label: 'Date',
        compute: rowData => {
            const { createdAt } = rowData;
            return moment(createdAt);
        },
        renderer: data => data.format('DD/MM/YYYY')
    },
    {
        key: 'epicorId',
        label: 'Epicor ID'
    },
    { key: 'customer.firstName', label: 'First Name' },
    { key: 'customer.lastName', label: 'Last Name' },
    { key: 'customer.email', label: 'Email' },
    { key: 'name', label: 'ID' },
    { key: 'source', label: 'Source' },
    {
        key: 'totals.total',
        label: 'Total',
        compute: rowData => {
            const { totals } = rowData;
            if (totals && totals.length > 0) {
                return Number(totals[0].total).toFixed(2);
            }
            return '-';
        },
        renderer: data => `$ ${data}`,
        sortData: (a, b, order) => {
            if (order === 'desc') {
                return Number(b.totals[0].total).toFixed(2) - Number(a.totals[0].total).toFixed(2)
            }
            return Number(a.totals[0].total).toFixed(2) - Number(b.totals[0].total).toFixed(2)
        }
    },
    {
        key: 'beanDollars',
        label: 'Bean dollars',
        compute: ({ beanDollars }) => beanDollars.toFixed(2) || 0.0,
        renderer: data => `$${data}`,
        sortData: (a, b, order, compute) => {
            /*eslint-disable*/
            return order === 'desc'
                ? compute(b) - compute(a)
                : compute(a) - compute(b);
        }
    }
];

const OrdersList = ({ epicorView }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);

    const orders = useSelector(state => selectAllOrders(state));
    const orderLoadingState = useSelector(state => selectOrdersLoading(state));
    const meta = useSelector(state => selectOrdersMeta(state));

    const givexOrdersLoading = useSelector(state =>
        selectGivexOrdersLoading(state)
    );
    const unsycnedGivexOrders = useSelector(state =>
        selectUnsyncedGivexOrders(state)
    );

    // Convert URL params to state
    const initialFilters = {
        search: params.get("query") || "",
        source: params.get("source") || "Shopify",
        epicorYes: params.get("epicorYes") === "true", // Convert string to boolean
        epicorNo: params.get("epicorNo") !== "false", // Default is true
        dateFrom: params.get("dateFrom") ? moment.unix(params.get("dateFrom")) : moment().subtract(6, "w"),
        dateTo: params.get("dateTo") ? moment.unix(params.get("dateTo")) : moment().add(1, "day"),
        currentPage: Number(params.get("currentPage")) || 0,
        pageSize: Number(params.get("pageSize")) || 100,
        sortBy: params.get("sortBy") || "createdAt",
        order: params.get("order") || "desc",
    };

    const [filters, setFilters] = useState(initialFilters);

    // Update URL whenever filters change
    useEffect(() => {
        const newParams = new URLSearchParams();

        if (filters.search) newParams.set("query", filters.search);
        if (filters.source && filters.source !== "Shopify") newParams.set("source", filters.source);

        newParams.set("epicorYes", filters.epicorYes);
        newParams.set("epicorNo", filters.epicorNo);

        // Convert dates to Unix timestamps before saving to URL
        if (filters.dateFrom) newParams.set("dateFrom", filters.dateFrom.unix());
        if (filters.dateTo) newParams.set("dateTo", filters.dateTo.unix());

        newParams.set("currentPage", filters.currentPage);
        newParams.set("pageSize", filters.pageSize);
        newParams.set("sortBy", filters.sortBy);
        newParams.set("order", filters.order);

        history.replace({ search: newParams.toString() }); // Update URL without reloading
    }, [filters, history]);



    const getPaginatedOrders = () => {
        dispatch({
            type: GET_ALL_ORDERS,
            currentPage: filters.currentPage,
            pageSize: filters.pageSize,
            search: filters.search,
            source: filters.source,
            dateFrom: filters.dateFrom.unix(),
            dateTo: filters.dateTo.unix(),
            epicorNo: filters.epicorNo,
            epicorYes: filters.epicorYes,
            sortBy: filters.sortBy,
            order: filters.order
        });
    };

    useEffect(() => {
        if (epicorView) {
            getPaginatedOrders();
        }
    }, [filters, epicorView]);

    const onFilterChange = type => event => {
        if (type === 'epicorYes' || type === 'epicorNo') {
            return setFilters(f => ({ ...f, [type]: !filters[type], currentPage: 0 }));
        }

        if (type === 'dateFrom' || type === 'dateTo') {
            return setFilters(f => ({ ...f, [type]: event, currentPage: 0 }));
        }

        const { value } = event.target;
        setFilters(f => ({ ...f, [type]: value, currentPage: 0 }));
    };

    const onChangeRowsPerPage = size =>
        setFilters(f => ({ ...f, pageSize: size, currentPage: 0 }));

    const onChangePage = pageNum =>
        setFilters(f => ({ ...f, currentPage: pageNum }));

    const onSort = sortData =>
        setFilters(f => ({
            ...f,
            sortBy: sortData.property,
            order: sortData.newOrder,
            currentPage: 0
        }));

    const viewOrderDetails = orderData =>
        history.push({
            pathname: `/orders/${orderData.orderId}`, state: {
                searchQuery: history.location.search
            }
        });

    const rowStyle = order => {
        const cutoverDate =
            process.env.NODE_ENV === 'production'
                ? window.__env__.BEAN_DOLLAR_START_DATE
                : process.env.REACT_APP_BEAN_DOLLAR_START_DATE;

        const errorStyle = { backgroundColor: 'rgba(255, 0, 0, 0.1)' };

        const notSynced =
            !order.loyaltyRewardsSynced &&
            moment(order.createdAt).isAfter(cutoverDate);

        if (!epicorView && notSynced) return errorStyle;

        const noEpicor = order.epicorId === '';

        if (epicorView && noEpicor) return errorStyle;

        return {};
    };

    const computedLoading = epicorView ? orderLoadingState : givexOrdersLoading;
    const computedData = epicorView ? orders : unsycnedGivexOrders;
    const computedMeta = epicorView ? meta.count : unsycnedGivexOrders.length;
    return (
        <>
            <Box marginTop={2.5} />
            {epicorView && (
                <OrderFilter
                    search={filters.search}
                    source={filters.source}
                    orderDate={filters.orderDate}
                    epicorYes={filters.epicorYes}
                    epicorNo={filters.epicorNo}
                    fromDate={filters.dateFrom}
                    toDate={filters.dateTo}
                    onChange={onFilterChange}
                    numberOfOrders={Number(computedMeta)}
                    resetFilter={() => {
                        setFilters({
                            search: "",
                            source: "Shopify",
                            epicorYes: false, // Convert string to boolean
                            epicorNo: true, // Default is true
                            dateFrom: moment().subtract(6, "w"),
                            dateTo: moment().add(1, "day"),
                            currentPage: 0,
                            pageSize: 100,
                            sortBy: "createdAt",
                            order: "desc",
                        })
                        history.replace({ search: '' })
                    }}
                />
            )}
            <Table
                page={filters.currentPage}
                pagination
                loading={computedLoading}
                headerData={headerData}
                tableData={computedData}
                onRowClick={viewOrderDetails}
                serverSidePagination={epicorView}
                serverSideSorting={epicorView}
                count={Number(computedMeta)}
                pageChanged={onChangePage}
                onChangePage={onChangePage}
                rowsPerPageChanged={onChangeRowsPerPage}
                defaultSortDir="desc"
                defaultSortKey="createdAt"
                showEmptyRows={false}
                onSort={onSort}
                rowStyle={rowStyle}
                defaultRowsPerPage={100}
            />
        </>
    );
};

OrdersList.propTypes = {
    epicorView: PropTypes.bool
};

OrdersList.defaultProps = {
    epicorView: false
};

export default OrdersList;
