import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ShippingIcon from '@material-ui/icons/LocalShipping';
import VoucherIcon from '@material-ui/icons/LocalPlay';
import PromoIcon from '@material-ui/icons/LocalOffer';

import { selectOrdersLoading } from '@/state/orders/reducers';

import ImagePlaceholder from '@/components/ImagePlaceholder/ImagePlaceholder';
import Box from '@/components/Box/Box';
import Loader from '@/components/Loader/Loader';
import Table from '@/components/Table/Table';
import TextInput from '@/components/TextInput/TextInput';
import { useState } from 'react';

const styles = makeStyles({
    totalsContainer: {
        // '& .MuiTypography-root': {
        // },
        fontSize: 11,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: '15px 0px',

    },
    totalPrice: {
        fontWeight: 700
    },
    label: {
        fontSize: 11,
        color: '#a7a7a7'
    },
    authority: {
        fontSize: 14
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    itemRow: {
        fontSize: 11,
        display: 'flex',
        flex: 1,
        padding: '15px 0px',
        alignItems: 'center',
        borderBottom: '1px solid #ebebeb',
        margin: '0px -20px',
        cursor: 'pointer',
        justifyContent: 'space-between',
        '&:hover': {
            backgroundColor: '#ebebeb'
        }
    },
    itemColumn: {
        padding: 10,
    },
    row: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        margin: '0px -20px',
    }
});

const ItemsOrdered = props => {
    const { items, shipping, totals, discountCodes, orderNote: initialOrderNote, noteAttributes, history, readOnly, onChange } = props;

    const classes = styles();

    const loadingState = useSelector(state => selectOrdersLoading(state));

    const [orderData, setOrderData] = React.useState({
        items: [],
        shippingType: '',
        shippingPrice: '',
        voucher: '',
        voucherPrice: '',
        promo: '',
        promoPrice: '',
        totalPrice: ''
    });

    const [orderNote, setOrderNote] = useState(initialOrderNote || '');

    useEffect(() => {
        setOrderNote(initialOrderNote)
    }, [initialOrderNote]);


    useEffect(() => {
        if (!loadingState) {
            const _shipping = shipping.length > 0 && shipping[0];
            setOrderData({
                items,
                shippingType: _shipping && _shipping.title,
                shippingPrice: _shipping && _shipping.price,
                totalPrice: totals[0].total,
                promo: discountCodes.length > 0
                    ? discountCodes.map(discount => discount.code).join(' ')
                    : '',
                promoTotal: totals[0].discounts
            });
        }
    }, [loadingState]);

    const hasMeta =
        orderData.shippingType !== '' ||
        orderData.voucher !== '' ||
        orderData.promo !== '';

    const onRowClick = (rowData) => {
        const { sku } = rowData;
        history.push(`/parts/${sku}`)
    }

    const isGift = noteAttributes.find(attr => attr.name === 'isGift');
    const giftNote = noteAttributes.find(attr => attr.name === 'giftNote');
    const authorityToLeave = noteAttributes.find(attr => attr.name === 'authorityToLeave');

    const notesSize = giftNote ? 4 : 7;

    const handleOrderNoteChange = (e) => {
        const newOrderNote = e.target.value;
        setOrderNote(newOrderNote);

        onChange(newOrderNote); // Notify parent
    };



    return (
        <Box title="Items ordered">
            <Loader loading={loadingState}>
                <div className={classes.itemsContainer}>
                    {
                        orderData.items.map((item, i) => {
                            return (
                                <div role='presentation' key={`orderItems-${i}`} className={classes.itemRow} onClick={() => onRowClick(item)}>
                                    <div style={{ minWidth: 200, flexBasis: 250, marginLeft: 100 }} className={classes.itemColumn}>{item.title}</div>
                                    <div style={{ minWidth: 150, flexBasis: 100 }} className={classes.itemColumn}>{item.grind}</div>
                                    <div style={{ minWidth: 200, flexBasis: 350 }} className={classes.itemColumn}>{item.sku}</div>
                                    <div style={{ maxWidth: 40, minWidth: 40, margin: '0 20px' }} className={classes.itemColumn}>{item.quantity}</div>
                                    <div style={{ minWidth: 65, display: 'flex', justifyContent: 'flex-end', marginRight: 50 }} className={classes.itemColumn}>${item.price}</div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    orderData.items.length > 0 &&
                    <React.Fragment>
                        {
                            hasMeta &&
                            <React.Fragment>
                                <div className={classes.totalsContainer}>
                                    {
                                        orderData.shippingType &&
                                        <div className={classes.row}>
                                            <div style={{ flexBasis: 100, display: 'flex', justifyContent: 'center' }} className={classes.itemColumn}><ShippingIcon /></div>
                                            <div style={{ flex: 1 }} className={classes.itemColumn}>{orderData.shippingType}</div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 50 }} className={classes.itemColumn}>{`$${orderData.shippingPrice}`}</div>
                                        </div>
                                    }
                                    {
                                        orderData.voucher &&
                                        <div className={classes.row}>
                                            <div style={{ flexBasis: 100, display: 'flex', justifyContent: 'center' }} className={classes.itemColumn}><VoucherIcon /></div>
                                            <div style={{ flex: 1 }} className={classes.itemColumn}>Voucher used</div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 50 }} className={classes.itemColumn}>- $ Voucher price</div>
                                        </div>
                                    }
                                    {
                                        orderData.promo &&
                                        <div className={classes.row}>
                                            <div style={{ flexBasis: 100, display: 'flex', justifyContent: 'center' }} className={classes.itemColumn}><PromoIcon /></div>
                                            <div style={{ flex: 1 }} className={classes.itemColumn}>{orderData.promo}</div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 50 }} className={classes.itemColumn}>{`- $${orderData.promoTotal}`}</div>
                                        </div>
                                    }
                                </div>
                                <div className={classes.totalsContainer}>
                                    <div className={classes.row}>
                                        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', marginRight: 50, fontWeight: 'bold' }} className={classes.itemColumn}>$ {orderData.totalPrice}</div>
                                    </div>
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={notesSize}>
                                        <TextInput
                                            multiline
                                            value={orderNote || ''}
                                            label="Order notes"
                                            readOnly={readOnly}
                                            onChange={handleOrderNoteChange}
                                        />
                                    </Grid>
                                    {giftNote ? (
                                        <Grid item xs={4}>
                                            <TextInput
                                                multiline
                                                value={giftNote.value}
                                                label="Gift notes"
                                                readOnly={readOnly}
                                            />
                                        </Grid>) :
                                        (<Grid item xs={1} />)
                                    }
                                    <Grid item xs={2}>
                                        <Grid
                                            container
                                            direction="column"
                                            justify="center"
                                            style={{ height: '100%' }}
                                        >
                                            <Typography className={classes.label}>Marked as a gift?</Typography>
                                            <Typography className={classes.authority}>{isGift ? isGift.value : 'No'}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Grid
                                            container
                                            direction="column"
                                            justify="center"
                                            style={{ height: '100%' }}
                                        >
                                            <Typography className={classes.label}>Authority to leave</Typography>
                                            <Typography className={classes.authority}>{authorityToLeave ? authorityToLeave.value : 'No'}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </Loader>
        </Box>
    );
};

ItemsOrdered.propTypes = {
    items: PropTypes.array,
    shipping: PropTypes.array,
    totals: PropTypes.array,
    discountCodes: PropTypes.array,
    orderNote: PropTypes.string,
    noteAttributes: PropTypes.array,
    history: PropTypes.object.isRequired,
    onChange: PropTypes.func,
};

ItemsOrdered.defaultProps = {
    items: [],
    shipping: [],
    totals: [],
    discountCodes: [],
    orderNote: '',
    noteAttributes: [],
    onChange: () => { },
};

export default withRouter(ItemsOrdered);
