import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import Photo from '@material-ui/icons/Photo';

const ImagePlaceholder = props => {
    const { imageUrl, styles } = props;

    const style = makeStyles(theme => ({
        imageContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: imageUrl ? null : '#dadada',
            ...styles,
        },
        icon: {
            color: '#bababa'
        }
    }));

    const classes = style();

    return (
        <div style={styles} className={classes.imageContainer}>
            {
                imageUrl
                    ? <img src={imageUrl} width={styles.width} height={styles.height} alt='placeholder_image' style={{ objectFit: 'contain' }} />
                    : <Photo className={classes.icon} />
            }
        </div>
    );
};

ImagePlaceholder.propTypes = {
    styles: PropTypes.object,
    imageUrl: PropTypes.string,
};

ImagePlaceholder.defaultProps = {
    styles: {
        width: '150px',
        height: '150px',
        marginBottom: 10,
    },
    imageUrl: null,
}

export default ImagePlaceholder;